import React from 'react';
import './ConnectButton.css';

type ConnectButtonProps = {
  termsAccepted: boolean;
  useEthereum: boolean;
  connectMetamask: () => void;
  useSolana: boolean;
  connectPhantom: () => void;
  backgroundColor: string;
  color: string;
  hasEthWallet: boolean;
  hasSolanaWallet: boolean;
  setSkipOtherWallet: () => void;
}
const ConnectButton = (props: ConnectButtonProps) => {

  return (
    <>
      {props.useEthereum && 
        <button 
          className="connect_button" 
          onClick={props.connectMetamask} 
          disabled={!props.termsAccepted || props.hasEthWallet} 
          style={{ backgroundColor: props.backgroundColor, color: props.color,}}
        >
          {props.hasEthWallet ? 'Connected' : 'Connect'} to Metamask
        </button>
      }
      {props.useSolana && 
        <button
          className="connect_button"
          onClick={props.connectPhantom}
          disabled={!props.termsAccepted || props.hasSolanaWallet}
          style={{ backgroundColor: props.backgroundColor, color: props.color,}}
        >
          {props.hasSolanaWallet ? 'Connected' : 'Connect'} to Phantom
        </button>
      }
      {(props.useSolana && props.useEthereum) &&
        <button
          className="connect_button"
          onClick={props.setSkipOtherWallet}
          disabled={!props.hasEthWallet && !props.hasSolanaWallet}
          style={{ backgroundColor: props.backgroundColor, color: props.color }}
        >
          Launch
        </button>
      }
    </>
  )
}

export default ConnectButton;