import { WorldConfig } from '../world-config';
import metaopsPfpList from '../../world-specific/metatravelers/metaops_pfps.json';
import metatravCommonPassList from '../../world-specific/metatravelers/metatrav_common_founders.json';

export const MetatravelersConfig: WorldConfig = {
  isTest: true,
  worldName: 'Mira Prime',
  logoAltText: 'MetaTravelers',
  clientNfts: [
    {name: 'MetaTravelers', collectionUrl: 'https://www.metatravelers.io/'}
  ],
  controlsText: 'Mira Prime Controls',
  adminWallets: [
    "0x557e5F91f60dc99aB499F6ffdbedFfBa44b441D9",
    "0xc36596E05312410fC41337eC6c2914A4FCA8d6C1",
    "0x033b13A18786C714e12fdEE8E62843bDf3b9224c",
    "0x102DD33ef3c1af8736EDdCc30985fEB69e099cD8",
    "0x1092B0b76574bd038aEEaccf57A6FD93551B60f7",
    "0x6D000551d4769A31e4c6f8e34F9a2B8e570BEb07",
    "0xb79a3a5BaCC45d4505d5E495d73FC871E4fB77C9",
    "0xcB013B53C27cA602A26C0ab722434A8316a192AD",
    "0x46F8351307482C162d7267F0fe08d5efd8Ba3BF1",
    "0x56dF5438eF630d6c7d8Cc872E55005f961131aFe",
    "0x0EbD8bED75AC0C2a30e0200FcBc51f9f2BE11369"
  ],
  gameUrl: "https://connector_prod.stream.leapn.life/v5/Ferb/metaTravelersxx08xx22xx22xxreKGxxship/metatravelers",
  trackTokenList: true,
  // contract config
  contractCustomization: {
    useEthereum: true,
    useERC721Contract: true,
    // correct contract name
    // ERC721ContractName: 'Metatravelers: Nbiru',
    // incorrect contract name used due to bad backend implementation
    ERC721ContractName: 'MetaTravelers',
    ERC721ContractAddress: "0x5372f926b34Be60aC1436372107C3eE8c6e056E5",
    ERC721ContractAbi: [
      "function balanceOf(address _owner) external view returns (uint256 balance)"
    ],
    useERC1155Contract: true,
    ERC1155ContractAddress: "0xA413f007BB3f20595b7f304ffd2E580481433a16",
    ERC1155ContractName: 'MetaTravelers: Genesis Stone',
    ERC1155ContractAbi: [
      "function balanceOf(address _owner, uint256 _id) external view returns (uint256)",
      "function balanceOfBatch(address[] accounts, uint256[] ids) external view returns (uint256[])"
    ],
    tokenIdList: [],
    useSolana: true,
    solanaContracts: [
      { 
        // correct token name
        // tokenName: 'MetaOps PFP',
        // incorrect token name used due to bad backend implementation
        tokenName: 'MetaOps',
        creators: [
          'GU2nTh3aWQz4AA8Gaih5AmV8WmKt3Y8P6iwy5t9347h',
          '8CWFS9nzXtLd3LGE3GaSLYTAEUzkAoUPwmtq32nJFkSZ',
          'HMduKVo3A19U5EpQdEhPjo9hq9zfZXn8aGVYZp7Vc7fX'
        ],
        tokenList: metaopsPfpList
      },
      { 
        tokenName: 'Common Founders Pass', 
        creators: [
          'HZSQf9Qvdmmn1B5e7YBxWnyoafTUFtqjrw8SqvSRwace',
          '8CWFS9nzXtLd3LGE3GaSLYTAEUzkAoUPwmtq32nJFkSZ',
          'HMduKVo3A19U5EpQdEhPjo9hq9zfZXn8aGVYZp7Vc7fX'
        ],
        tokenList: metatravCommonPassList
      }
    ]
  },
  useWallet: true,
  useContractAuth: true,
  useFoundersAuth: false,
  useAlphaFoundersAuth: false,
  useGoldnVipAuth: false,
  useRoyalVipAuth: false,
  checkboxBackground: 'transparent',
  textColor: 'white',
  buttonColor: '#7f4db0',
  checkedColor: '#7f4db0',
  linkTextColor: '#cfb8e5',
  buttonTextColor: 'white',
  maxPlayers: 15
}
